@import url("https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Roboto:wght@400;700&display=swap");

body {
  font-family: "Roboto", sans-serif;
  background-color: #f0f4f8;
  color: #333;
  margin: 0;
  padding: 0;
}

.map-container {
  margin-top: 20px;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.above-card-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1200px; /* Matches the max-width of .card */
  margin-bottom: 20px; /* Add some spacing between this container and the card */
}

.text-and-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.buttons-container {
  display: flex;
  gap: 10px; /* Adds 10px space between the buttons */
}

.buttons-container button {
  height: 80px; /* Match the height of the logo */
  padding: 0 20px; /* Adjust padding for a better look */
  background-color: #007acc; /* Primary button color */
  color: white;
  border: none;
  border-radius: 10px; /* Rounded corners for a modern look */
  font-size: 1.2em; /* Slightly larger text */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition effects */
}

.buttons-container button:hover {
  background-color: #005999; /* Darker shade on hover */
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Add shadow for depth */
}

.logo {
  top: 20px;
  left: 20px;
  width: 80px; 
  height: auto;
}

.project-logo {
  margin-left: 10px;
  width: auto;
  height: 50px;
}

.app-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.title-subtitle {
  margin-left: 10px; /* This will push the title and subtitle to the right, away from the logo */
  cursor: pointer;
}

.title {
  font-family: "Arial";
  font-size: 2.5em;
  color: #007acc;
  margin-bottom: 1px;
  margin-top: 10px;
}

.subtitle {
  font-family: "Playfair Display", serif;
  font-size: 1.5em;
  color: #007acc;
  margin-bottom: 20px;
  margin-top: 1px;
}

.selection-container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.area-select {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.area-select label {
  font-size: 1.2em;
  margin-right: 10px;
}

.area-select select {
  padding: 8px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #007acc;
  background-color: #ffffff;
  color: #007acc;
}

.municipality-select {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.municipality-select label {
  font-size: 1.2em;
  margin-right: 10px;
}

.municipality-select select {
  padding: 8px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #007acc;
  background-color: #ffffff;
  color: #007acc;
}

.language-button {
  width: 40px;
  height: 30px;
  padding: 0;
  font-size: 0.7em;
  border-radius: 5px;
  margin-left: 8px;
  background-color: #007acc;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.language-button:hover {
  background-color: #005999;
}

.above-card-container {
  width: 100%;
  max-width: 1200px; /* This matches the max-width of .card */
  margin-bottom: 20px; /* Optional: Add some spacing between this container and .card */
}

.card {
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  padding: 30px;
  max-width: 1200px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.centered-line {
  text-align: center;
  font-size: 1.2em;
  color: #333;
  margin-bottom: 20px;
}

.large-arrow {
  font-size: 1.5em;
  font-weight: bold;
}

.charts-container {
  flex: 2;
  margin-right: 30px;
}

.section-name {
  display: flex;
  justify-content: center; /* This will center the text */
  color: grey; /* Change to grey */
  font-size: 1.5em; /* Optional: Adjust font size if needed */
  margin-bottom: 10px; /* Optional: Add some space below */
}

.chart-container {
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  padding: 5px;
  margin-bottom: 5px;
}

.chart-wrapper {
  padding-right: 30px; /* Increase this value to add more space on the right side */
  overflow: visible; /* Ensures labels are visible even if they extend beyond the container */
}

.chart-header {
  display: flex;
  font-size: 1.3em;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1px;
  margin-left: 5px;
  color: #007acc;
}

.chart-header div {
  cursor: pointer;
}

.chart-container h3 {
  margin: 0;
  color: #333;
}

.legend-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 15px;
  flex-direction: column;
  background-color: #f0f4f8;
  border-radius: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  margin: 6px;
}

.legend-label {
  margin-left: 8px;
  font-size: 0.9em;
  color: #000000;
}

.sliders-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: -webkit-sticky; /* For Safari */
  position: sticky;
  top: 20px; /* Adjust this value as needed */
  z-index: 1000; /* Ensure it's above other content */
}

.sliders-container button {
  height: 20px; /* Match the height of the logo */
  padding: 0 20px; /* Adjust padding for a better look */
  background-color: #007acc; /* Primary button color */
  color: white;
  border: none;
  border-radius: 6px; /* Rounded corners for a modern look */
  font-size: 0.8em; /* Slightly larger text */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition effects */
  margin-bottom: 2px;
}

.slider-container {
  margin-bottom: 20px;
}

.slider-label {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  color: #007acc;
}

.slider-label label {
  cursor: pointer;
}

.slider-string {
  margin-bottom: 20px;
  font-size: 0.8em;
  font-weight: bold;
  color: #007acc;
  text-align: center;
}
.slider-scale {
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
  cursor: pointer;
}

.slider-scale-value {
  font-size: 0.8em;
  color: #007acc;
}

.info-icon {
  cursor: pointer;
  font-size: 1.2em;
  color: #007acc;
}

.info-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.2);
  padding: 30px;
  z-index: 2000;
  max-width: 450px;
  width: 90%;
}

.info-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1.1em;
  line-height: 1.6em;
  color: #333333;
  text-align: left;
  max-height: 400px; /* Set max height */
  overflow-y: auto; /* Enable vertical scrolling */
  padding-right: 10px; /* Optional: add padding for a better scroll experience */
}

.info-content p {
  margin-bottom: 10px;
  text-align: left;
  color: #333;
}

.info-content button {
  height: 30px; /* Match the height of the logo */
  padding: 0 20px; /* Adjust padding for a better look */
  background-color: #007acc; /* Primary button color */
  color: white;
  border: none;
  border-radius: 6px; /* Rounded corners for a modern look */
  font-size: 0.8em; /* Slightly larger text */
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s; /* Smooth transition effects */
  margin-bottom: 2px;
}

.info-content button:hover {
  background-color: #005999;
}

input[type="range"] {
  width: 100%;
  cursor: pointer;
}

input[type="range"]::-webkit-slider-thumb {
  appearance: none;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #007acc;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background: #007acc;
  cursor: pointer;
}

button {
  padding: 8px 12px;
  background-color: #007acc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #005999;
}

.chart-header button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007acc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chart-header button:hover {
  background-color: #005999;
}

.chart-header button {
  margin-left: 10px;
  padding: 5px 10px;
  background-color: #007acc;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chart-header button:hover {
  background-color: #005999;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
